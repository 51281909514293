import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    key: "material_name",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
    key: "material_number",
  },
  {
    title: T("调拨数量"),
    dataIndex: "total_quantity",
    key: "total_quantity",
  },
  {
    title: T("单位"),
    dataIndex: "unit",
    key: "unit",
  },
];
